<template>
  <div class="row">
    <div class="col-12">
      <section class="card shadow w3-white mb-4">
        <div class="card-body m-0 bg-transparent">
          <div class="row mb-2">
            <div class="col-sm-5">
              <h4 class="bold">Liquidations</h4>
            </div>
            <div class="col-sm-7">
              <div class="w3-right mb-2 ml-2">
                <DateRangeFilter @gettransactions="getTransactions" />
              </div>
              <select
                v-model="fundKey"
                class="form-control width100 w3-right mb-2"
              >
                <option
                  v-for="(key, i) in productType"
                  :key="key"
                  :value="key"
                  :selected="i === 0"
                >
                  {{ key }}
                </option>
              </select>
            </div>
          </div>
          <div v-if="fetching">
            <img class="mr-3" src="base/img/loading.gif" width="40" />
            Loading liquidation
          </div>
          <div v-else>
            <div v-for="(key, i) in productType" :key="key">
              <div :class="[fundKey === key ? 'd-block' : 'd-none']">
                <TransactionsTable
                  :tableid="'table-' + key + '-' + i"
                  :headers="TableHeader"
                  :rows="TransactionsList[key]"
                  source="liquidation"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import { useStore } from "vuex";
import { computed, onMounted, ref, onBeforeMount } from "vue";
import TransactionsTable from "@/components/TransactionsTable";
import DateRangeFilter from "@/components/DateRangeFilter";
export default {
  components: { TransactionsTable, DateRangeFilter },
  setup() {
    const store = useStore();
    const fetching = computed(() => store.state.fetching.RedemptionALLHistory);
    const productType = computed(() => store.state.InvestmentCodes);
    const fundKey = ref("");

    const activeRedemption = ref(productType.value[0]);
    const Period = ref({
      StartDate: "",
      EndDate: "",
    });
    const Transactions = computed(() => store.state.RedemptionALLHistory);
    if (!Transactions.value?.length) {
      store.dispatch("RedemptionALLHistory", {
        StartDate: "",
        EndDate: "",
      });
    }

    const TransactionsList = computed(() => {
      const Trans = {};
      for (let trans in Transactions.value) {
        let FundCode = Transactions.value[trans].FundCode;

        if (typeof Trans[FundCode] === "undefined") {
          Trans[FundCode] = [];
        }
        Trans[FundCode].push(Transactions.value[trans]);
      }
      return Trans;
    });

    const TableHeader = ref([
      { text: "Redemption Date", value: "RedemptionDate" },
      { text: "Amount", value: "Amount" },
      { text: "Unit Price", value: "UnitPrice" },
      { text: "Unit Quantity", value: "UnitQuantity" },
      { text: "Fund Code", value: "FundCode" },
    ]);

    const getTransactions = (period) => {
      store.dispatch("RedemptionALLHistory", {
        StartDate: period.StartDate,
        EndDate: period.EndDate,
      });
    };
    onBeforeMount(() => {
      store.dispatch("resetIdleTime");
    });
    onMounted(() => {
      fundKey.value = productType.value[0];
    });
    return {
      TableHeader,
      Transactions,
      fetching,
      productType,
      getTransactions,
      activeRedemption,
      TransactionsList,
      Period,
      fundKey,
    };
  },
};
</script>
<template>
  <section role="main" class="content-body">
    <header class="page-header">
      <h2>{{ title }}</h2>
    </header>
    <div class="p-4">
      <div class="row">
        <div class="col-md-10 w3-border-right">
          <LiquidationTransactions v-if="mounted" />
        </div>
        <div class="col-md-2"></div>
      </div>
    </div>
  </section>
</template> 
<script>
import LiquidationTransactions from "@/components/LiquidationTransactions";
import { onMounted, onBeforeMount, ref } from "vue";
import { useStore } from "vuex";
export default {
  components: {
    LiquidationTransactions,
  },

  setup() {
    const store = useStore();
    const title = ref("Liquidation history");
    const mounted = ref(false);
    onMounted(() => {
      mounted.value = true;
    });
    onBeforeMount(() => {
      store.dispatch("resetIdleTime");
    });
    return {
      title,
      mounted,
    };
  },
};
</script>
